import { Logtail } from "@logtail/browser";

export default (token) => {
  if (token && token !== "debug") {
    return new Logtail(token);
  } else if (token && token === "debug") {
    return console;
  } else {
    return { log: () => {}, info: () => {}, warn: () => {}, error: () => {} };
  }
};
