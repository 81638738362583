module Main exposing (..)

import Browser
import Html exposing (Html)
import Model exposing (Flags, ModalState(..), Model, Phase(..))
import Msg exposing (Msg(..))
import Random
import Task
import Time exposing (Month(..), millisToPosix)
import Time.Distance.Types exposing (DistanceId(..), Tense(..))
import Update exposing (ElementId(..), getQuestion, subscriptions, update)
import Url
import Url.Parser exposing ((</>), Parser, top)
import View



-- Init


init : Flags -> ( Model, Cmd Msg )
init flags =
    let
        currentRoute =
            toRoute flags.url
    in
    ( { seed = Random.initialSeed <| round flags.seed
      , timeZone = Time.utc
      , now = millisToPosix flags.now
      , config = flags.config
      , copy = flags.copy
      , phase =
            case currentRoute of
                Root ->
                    QuestionPhase
                        { title = ""
                        , description = ""
                        , author = ""
                        , phaseCode = Nothing
                        , modal = NoModal
                        }

                UnknownRoute ->
                    NotFound

                QuestionRoute _ ->
                    Loading
      }
    , Cmd.batch
        [ Task.perform AdjustTimeZone Time.here
        , case currentRoute of
            QuestionRoute questionId ->
                getQuestion questionId
                    |> Cmd.map ApiMsg

            _ ->
                Cmd.none
        ]
    )


type Route
    = Root
    | QuestionRoute String
    | UnknownRoute


route : Parser (Route -> route) route
route =
    Url.Parser.oneOf
        [ Url.Parser.map Root top
        , Url.Parser.map QuestionRoute Url.Parser.string
        ]


toRoute : String -> Route
toRoute urlAsString =
    case Url.fromString urlAsString of
        Nothing ->
            UnknownRoute

        Just url ->
            Maybe.withDefault UnknownRoute (Url.Parser.parse route url)



-- View


view : Model -> Html Msg
view model =
    case model.phase of
        NotFound ->
            View.notFound model.config model.copy

        Loading ->
            View.loading model.config model.copy

        QuestionPhase question ->
            View.questionPhase model question

        ProposalPhase question state ->
            View.proposalPhase model question state

        RatingPhase question state ->
            View.ratingPhase model question state

        ResultPhase question state ->
            View.resultPhase model question state



-- Main


main : Program Flags Model Msg
main =
    Browser.element
        { init = init
        , update = update
        , view = view
        , subscriptions = subscriptions
        }
