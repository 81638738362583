export const GetQuestionQuery = /* GraphQL */ `
  query GetQuestionQuery($id: uuid!) {
    questions_by_pk(id: $id) {
      id
      title
      phase
      phaseCodePublic
      author
      description
      createdAt
      expiresAt
      proposalsEndedAt
      ratingEndedAt
      proposals {
        id
        title
        description
        isExcluded
        createdAt
      }
      ratings {
        proposalId
        author
        points
        createdAt
      }
    }
  }
`;

export const PostNewQuestion = /* GraphQL */ `
  mutation PostNewQuestion(
    $title: String!
    $author: String!
    $description: String = null
    $phaseCode: String = null
    $phaseCodePublic: String = null
  ) {
    insert_questions_one(
      object: {
        title: $title
        description: $description
        author: $author
        phaseCode: $phaseCode
        phaseCodePublic: $phaseCodePublic
      }
    ) {
      id
      author
      description
      createdAt
      expiresAt
      phase
      title
      phaseCodePublic
    }
  }
`;

export const PostNewProposal = /* GraphQL */ `
  mutation PostNewProposal(
    $questionId: uuid!
    $title: String!
    $description: String = null
  ) {
    insert_proposals_one(
      object: {
        questionId: $questionId
        title: $title
        description: $description
      }
    ) {
      id
      title
      isExcluded
      description
      createdAt
    }
  }
`;

export const ExcludeProposal = /* GraphQL */ `
  mutation ExcludeProposal($id: uuid!, $isExcluded: Boolean!) {
    update_proposals_by_pk(
      pk_columns: { id: $id }
      _set: { isExcluded: $isExcluded }
    ) {
      id
    }
  }
`;

export const GoToRatingPhase = /* GraphQL */ `
  mutation GoToRatingPhase(
    $id: uuid!
    $phase: String!
    $proposalsEndedAt: timestamptz
  ) {
    update_questions_by_pk(
      pk_columns: { id: $id }
      _set: { phase: $phase, proposalsEndedAt: $proposalsEndedAt }
    ) {
      id
      title
      phase
      author
      description
      createdAt
      expiresAt
      proposalsEndedAt
      ratingEndedAt
      proposals {
        id
        title
        description
        isExcluded
        createdAt
      }
      ratings {
        proposalId
        author
        points
        createdAt
      }
      phaseCodePublic
    }
  }
`;

export const GoToResultPhase = /* GraphQL */ `
  mutation GoToResultPhase(
    $id: uuid!
    $phase: String!
    $ratingEndedAt: timestamptz
  ) {
    update_questions_by_pk(
      pk_columns: { id: $id }
      _set: { phase: $phase, ratingEndedAt: $ratingEndedAt }
    ) {
      id
      title
      phase
      author
      description
      createdAt
      expiresAt
      proposalsEndedAt
      ratingEndedAt
      proposals {
        id
        title
        description
        isExcluded
        createdAt
      }
      ratings {
        proposalId
        author
        points
        createdAt
      }
      phaseCodePublic
    }
  }
`;

export const PostRatings = /* GraphQL */ `
  mutation PostRatings($ratings: [ratings_insert_input!]!) {
    insert_ratings(objects: $ratings) {
      returning {
        id
        author
        points
        proposalId
        createdAt
      }
    }
  }
`;

export const DeletedQuestion = /* GraphQL */ `
  mutation DeletedQuestion($id: uuid!) {
    delete_questions_by_pk(id: $id) {
      id
    }
  }
`;

export const SubscribeToPhaseChanges = /* GraphQL */ `
  subscription SubscribeToPhaseChanges($id: uuid!) {
    questions_by_pk(id: $id) {
      proposalsEndedAt
      ratingEndedAt
    }
  }
`;

export const SubscribeToNewProposals = /* GraphQL */ `
  subscription SubscribeToNewProposals($id: uuid!) {
    questions_by_pk(id: $id) {
      proposals {
        id
        title
        description
        isExcluded
        createdAt
      }
    }
  }
`;

export const SubscribeToNewRatings = /* GraphQL */ `
  subscription SubscribeToNewRatings($id: uuid!) {
    questions_by_pk(id: $id) {
      ratings {
        proposalId
        author
        points
        createdAt
      }
    }
  }
`;
